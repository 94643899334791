import React from "react";
import "./style.scss";
import { motion } from "framer-motion";
import MeImage from "../../assets/me.png";
import BurgerMenu from "../BurgerMenu";
import resume from "../../assets/MAKRI-STAMATIA_RESUME.pdf";

const pageVariants = {
  initial: {
    opacity: 0,
    x: "-100vw",
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "100vw",
  },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 1,
};

const About = (props) => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="about"
    >
      <BurgerMenu />
      <div className="container wrapping">
        <div className="row">
          <div className="about-inner">
            <h1>About</h1>
            <p>
              Welcome to my portfolio! I’m Tina, a dedicated front-end developer
              with a deep passion for crafting beautiful and functional user
              interfaces. With a keen eye for design and a commitment to
              creating responsive, user-friendly experiences, I bring visions to
              life on the web.
            </p>
            <p>
              From a young age, I was fascinated by technology and the endless
              possibilities it offers to connect people and ideas. This
              fascination turned into a professional pursuit as I delved into
              the world of web development, specializing in front-end
              technologies. I thrive on the challenge of turning complex
              problems into intuitive solutions, using a blend of creativity and
              technical expertise.
            </p>
            <p>
              Most of what you see in this portfolio is the result of teamwork.
              It's very rare these days to create anything truly great all on
              your own. I deeply value collaboration and the dynamic exchange of
              ideas with others, which often leads to extraordinary outcomes.
              I'm passionate about working together with clients and colleagues
              to harness collective strengths, making every project not just a
              task, but a shared journey towards something exceptional.
            </p>
            <div className="CV">
              <a
                className="arrow-link"
                href={resume}
                download="MAKRI-STAMATIA_RESUME.pdf"
              >
                Download CV
              </a>
            </div>
          </div>
          <div className="img-wrapper">
            <div className="img-inner-wrapper">
              <img src={MeImage} alt="Me" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
