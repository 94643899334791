class NoiseEffect {
  constructor() {
    this.canvas = document.createElement("canvas");
    this.ctx = this.canvas.getContext("2d");
    // Apply the CSS directly to the canvas element for full coverage
    this.canvas.style.position = "fixed";
    this.canvas.style.top = "0";
    this.canvas.style.left = "0";
    this.canvas.style.width = "100%";
    this.canvas.style.height = "100%";
    this.canvas.style.zIndex = "-1";
    document.body.appendChild(this.canvas);
    this.resize();
    window.addEventListener("resize", () => this.resize());
    this.animateNoise();
  }

  resize() {
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
  }

  createNoise(opacity) {
    const imageData = this.ctx.createImageData(
      this.canvas.width,
      this.canvas.height
    );
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      // Generate random noise
      const noise = Math.random() * 255;
      // Set the noise with some transparency on a black background
      data[i] = noise; // Red channel
      data[i + 1] = noise; // Green channel
      data[i + 2] = noise; // Blue channel
      data[i + 3] = opacity; // Alpha channel for noise transparency
    }

    // Overlay the noise on a black background
    this.ctx.fillStyle = "black";
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.putImageData(imageData, 0, 0);
  }

  animateNoise() {
    this.createNoise(30); // The noise opacity can be changed here
    requestAnimationFrame(() => this.animateNoise());
  }
}

export default NoiseEffect;
