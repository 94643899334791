import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Work from "./components/Work";
import "./default.scss";
import NoiseEffect from "./components/Js/NoiseEffect";

function App() {
  const location = useLocation();

  useEffect(() => {
    const noise = new NoiseEffect();
    (() => noise)();

    // Custom cursor effect
    const cursor = document.querySelector(".dot");
    const outerCircle = document.querySelector(".outer-circle");
    const moveCursor = (e) => {
      const x = e.clientX;
      const y = e.clientY;
      cursor.style.left = `${x}px`;
      cursor.style.top = `${y}px`;
      outerCircle.style.left = `${x}px`;
      outerCircle.style.top = `${y}px`;
    };
    document.addEventListener("mousemove", moveCursor);

    // Cleanup
    return () => {
      document.removeEventListener("mousemove", moveCursor);
    };
  }, []);

  return (
    <div className="App">
      {/* <Link to="/">
        <img src="/logo.png" alt="Logo" className="logo" />
      </Link> */}
      <div className="dot"></div>
      <div className="outer-circle"></div>
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/work" element={<Work />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
