const projectsData = [
  {
    id: 1,
    title: "Pesticides Report",
    image: require("../../assets/pesticides.png"),
    description:
      "Informational platform for the European Food Safety Authority (EFSA), which serves as an essential resource for stakeholders and the general public",
    link: "https://multimedia.efsa.europa.eu/pesticides-report-2021/",
  },
  {
    id: 2,
    title: "MOMus - Museum of Contemporary Art",
    image: require("../../assets/momus.png"),
    description:
      "Digital presentation of the Museum of Contemporary Art, which aimed to bring art to a broader audience through an engaging online experience. ",
    link: " https://momus.gr/en/museum/museum-contemporary-art#museum",
  },
  {
    id: 3,
    title: "EFSA at a Glance",
    image: require("../../assets/efsa.png"),
    description:
      "Informational platform for the European Food Safety Authority (EFSA), which serves as an essential resource for stakeholders and the general public",
    link: " https://www.efsa.europa.eu/en/multimedia/efsa-at-a-glance#who-we-are",
  },
];

export default projectsData;
